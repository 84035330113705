<template>
  <v-dialog v-model="updateDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update {{ name }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="add.stock"
          :counter="stoclimit"
          :hint="mStock"
          :max="currstock"
          label="stock"
          persistent-hint
          type="number"
        ></v-text-field>

        <v-text-field v-model="add.price" label="price"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="$v.add.$invalid"
              color="blue"
              small
              text
              @click="submit"
              v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    updateDialog: Boolean,
    myId: { type: Number },
    currstock: { type: Number },
    name: { type: String },
    myStock: { type: Number },
  },
  data() {
    return {
      loading: false,
      validations: false,
      stoclimit: this.currstock,
      mStock: "Current stock: " + this.myStock,
      add: {
        stock: 0,
        price: 0,
      },
    };
  },
  validations: {
    add: {
      stock: { required },
      price: { required },
    },
  },
  watch: {
    "add.stock": function (a) {
      console.log(a);
      this.stoclimit = this.currstock;
      if (a >= this.currstock) {
        this.validations = true;
      } else {
        this.validations = false;
      }
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      console.log("myId", this.myId);
      this.loading = true;
      let self = this;
      Restful.subscribers.substocks
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.add.stock = response.data.stock;
          this.add.price = response.data.price;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeUpdate", payload);
    },
    submit() {
      let stock = this.add.stock;
      let price = this.add.price;
      let data = {
        stock,
        price,
      };
      // console.log("data:", data);
      let payload = { id: this.myId, data: data, state: false };
      this.$emit("submitUpdate", payload);
    },
  },
};
</script>
