<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="newDialog = true">
          new stock
        </v-btn>
        <SubserviceNew
          :myId="_decode(id)"
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <!-- <v-btn icon color="primary" @click="return">
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="substocklist"
            :items-per-page="10"
            :loading="loading"
            loader-height="1"
          >
            <template v-slot:item.brand_name="{ item }">
              {{ item.brand_name | capitalize }}
            </template>
            <template v-slot:item.brand_description="{ item }">
              {{ item.brand_description | capitalize }}
            </template>
            <template v-slot:item.medicine="{ item }">
              {{ item.medicine | capitalize }}
            </template>
            <template v-slot:item.stock="{ item }">
              {{ comma(item.stock) }}
            </template>
            <template v-slot:item.expiry="{ item }">
              {{ item.expiry | myDate }}
            </template>
            <template v-slot:item.price="{ item }">
              {{ currency(item.price) }}
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn
                color="primary"
                text
                x-small
                @click.stop="$set(viewDialog, item.id, true)"
              >
                view
              </v-btn>
              <SubserviceView
                v-if="viewDialog[item.id]"
                :myId="item.id"
                :viewDialog="viewDialog[item.id]"
                @closeView="closeView"
              />

              <v-btn
                color="success"
                text
                x-small
                @click.stop="$set(updateDialog, item.id, true)"
              >
                edit
              </v-btn>
              <SubserviceEdit
                v-if="updateDialog[item.id]"
                :currstock="item.stock"
                :myId="item.id"
                :myStock="item.stock"
                :name="item.brand_name"
                :updateDialog="updateDialog[item.id]"
                @closeUpdate="closeUpdate"
                @submitUpdate="submitUpdate"
              />

              <v-btn
                color="error"
                text
                x-small
                @click.stop="$set(removeDialog, item.id, true)"
              >
                delete
              </v-btn>
              <SubserviceRemove
                v-if="removeDialog[item.id]"
                :myId="item.id"
                :removeDialog="removeDialog[item.id]"
                @closeRemove="closeRemove"
                @submitRemove="submitRemove"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils";
import SubserviceNew from "../../components/substock/newone.vue";
import SubserviceView from "../../components/substock/view.vue";
import SubserviceEdit from "../../components/substock/edit.vue";
import SubserviceRemove from "../../components/substock/remove.vue";

export default {
  props: {
    id: { type: String },
    name: { type: String },
  },
  components: {
    SubserviceNew,
    SubserviceView,
    SubserviceEdit,
    SubserviceRemove,
  },
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      newDialog: false,
      editDialog: {},
      updateDialog: {},
      viewDialog: {},
      removeDialog: {},
      shopMapDialog: {},
      crumbs: [
        {
          link: true,
          text: "Dashboard",
          to: { name: "pharma.dash" },
        },
        {
          link: true,
          text: "Shops",
          to: { name: "pharma.shops" },
        },
        {
          link: true,
          text: this._decode(this.name),
          to: { name: "pharma.centers.stocks" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      subsidiary: {},
      substocklist: [],
      loading: false,
      headers: [
        { text: "name", value: "brand_name" },
        { text: "description", value: "brand_description" },
        { text: "medicine category", value: "medicine" },
        { text: "stock", value: "stock" },
        { text: "price", value: "price" },
        { text: "expiry", value: "expiry" },
        { text: "action", value: "id", align: "center" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      let self = this;
      Restful.subscribers.substocks
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.substocklist = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.substocks
        .create(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.newDialog = p.state;
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.substocks
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.updateDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.substocks
        .remove(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeMap(p) {
      this.$set(this.shopMapDialog, p.id, p.state);
    },
  },
};
</script>
