<template>
  <v-dialog v-model="newDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new stock</v-card-title>
      <v-card-text>
        <v-select
          v-model="add.pharmacy_stocksid"
          :items="mediclist"
          item-text="brand_name"
          item-value="id"
          label="pharmaceutical product"
          return-object
          :hint="medidesc"
          persistent-hint
        ></v-select>

        <v-text-field
          v-model="add.stock"
          :counter="stoclimit"
          :hint="myStock"
          :max="stoclimit"
          label="stock"
          persistent-hint
          type="number"
        ></v-text-field>

        <v-text-field
          v-model="add.price"
          label="price"
          type="number"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="$v.add.$invalid"
              color="blue"
              small
              text
              @click="submit"
              v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
    myId: { type: String },
  },
  data() {
    return {
      loading: false,
      mediclist: [],
      menu2: null,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      stoclimit: null,
      myStock: null,
      medidesc: null,
      add: {
        pharmacy_stocksid: null,
        stock: null,
        price: 0,
      },
      currTotal: 0,
    };
  },
  validations: {
    add: {
      pharmacy_stocksid: { required },
      stock: { required },
      price: { required },
    },
  },
  watch: {
    "add.pharmacy_stocksid": function (a) {
      console.log(a);
      this.medidesc =
        "Desc: " +
        a.brand_description +
        ", Manu.: " +
        a.manufacturer +
        ", Medicine Cat.: " +
        a.medicine;
    },
    "add.stock": function (a) {
      let sum = this.add.pharmacy_stocksid.stock - a;
      this.myStock = "Current Stock: " + sum;
    },
  },
  created() {
    this.pharmacat();
  },
  methods: {
    pharmacat() {
      this.loading = true;
      let self = this;
      Restful.subscribers.pharma_stocks
        .mediclist()
        .then((response) => {
          console.log(response.data);
          this.mediclist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let subscriber_subsidiariesid = parseInt(this.myId);
      let pharmacy_stocksid = this.add.pharmacy_stocksid.id;
      let stock = parseInt(this.add.stock);
      let price = parseInt(this.add.price);

      let data = { subscriber_subsidiariesid, pharmacy_stocksid, price, stock };
      console.log("data:", data);
      let payload = { data: data, state: false };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
