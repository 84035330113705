<template>
  <v-dialog v-model="viewDialog" max-width="1000" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View Detail</v-card-title>
      <v-card-text>
        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">brand name</div>
              <div class="myright">
                {{ view.brand_name | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">brand_description</div>
              <div class="myright">
                {{ view.brand_description | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">medicine category</div>
              <div class="myright">
                {{ view.medicine | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">barcode</div>
              <div class="myright">{{ view.bar_code }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">price</div>
              <div class="myright">{{ currency(view.price) }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">stock</div>
              <div class="myright">
                {{ comma(view.stock) }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">expiry</div>
              <div class="myright">
                {{ view.expiry | myDate }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">created</div>
              <div class="myright">
                {{ view.created | myDating }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">updated</div>
              <div class="myright">
                {{ view.updated | myDating }}
              </div>
              <div class="clearall"></div>
            </li>
            <!--
            <li>
              <div class="myleft">added by</div>
              <div class="myright">
                {{ view.user }}
              </div>
              <div class="clearall"></div>
            </li>
            -->
          </ul>
        </div>

        <br />
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
import Utils from "../../mixins/utils.js";

export default {
  props: {
    viewDialog: Boolean,
    myId: {
      type: Number,
      default: 0,
    },
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      view: {},
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      console.log("myId", this.myId);
      this.loading = true;
      let self = this;
      Restful.subscribers.substocks
        .find(this.myId)
        .then((response) => {
          // console.log(response);
          console.log(response.data);
          this.view = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = {
        id: this.myId,
        state: false,
      };
      this.$emit("closeView", payload);
    },
  },
};
</script>
